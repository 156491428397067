input.form-control::-webkit-inner-spin-button {
    display: block;
    opacity: 1;
    height: 34px;
    right: 0;
    position: absolute;
    top: 0;
    width: 30px;
}

.form-control {
    padding-right: 20px !important;
}
