.chatroom-container {
	/* background: white; */
	background: #f0f2f5;
	display: flex;
	height: calc(100vh - 50px);
	margin: -1.75rem;
}

.contacts-panel {
	width: 250px;
	overflow-y: auto;
	box-shadow: 0.025rem 0 0.525rem 0 rgba(31, 33, 36, 0.05);
	background: white;
	z-index: 2;
}

.contact {
	padding: 10px 12px;
	cursor: pointer;
	border-top: 1px solid #F5F5F6;
	/* border-bottom: 1px solid #E9EAEC;
	border-right: 1px solid #F5F5F6; */
	border-radius: 8px;
	margin: 4px;
}

	.contact.selected {
		/* background-color: #F4F5F7; */
		background-color: #d3fdd5;
		/* border-right: none; */
	}

	.contact .unread-indicator {
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		min-width: 16px;
		padding: 3px;
		height: 16px;
		border-radius: 16px;
		background-color: #ff0000;
		color: white;
	}

.chat-interface {
	flex-grow: 1;
	padding: 0;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
}

.messages {
	flex-grow: 1;
	overflow-y: auto;
}

.message {
	margin-bottom: 10px;
}

.timestamp {
	font-size: 0.75rem;
	margin-left: 10px;
	color: #666;
}

.message-input {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 10px;
	background: #fff;
	width: 100%;
	display: flex;
}

	.message-input input {
		flex-grow: 1;
		margin-right: 10px;
	}

.chat-info {
	width: 400px;
	padding: 10px;
	background-color: white;
	box-shadow: -0.025rem 0 0.525rem 0 rgba(31, 33, 36, 0.05);
	z-index: 2;
	/* overflow-x: hidden;
	overflow-y: scroll; */
}

#chatroom {
	z-index: 1;
	position: absolute;
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	top: 0;
	bottom: 60px;
	background-color: #efeae2;
	background-size: cover;
	background-position: center;
	color: #fff;
}

.t3-custom-livechat-list-box {
	overflow-x: hidden;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	height: calc(100% - 55px);
	padding-bottom: 35px;
}

.t3-custom-livechat-title {
	color: #45ff8b;
	font-size: 20px;
	font-weight: 600;
	line-height: 32px;
	margin: 12px auto;
	text-align: left;
}

.t3-livechat-list-box {
	text-align: left;
	width: 100%;
	padding-top: 1rem;
	padding-bottom: 0.5rem;
}

.t3-livechat-message-row {
	margin-bottom: 3px;
}

.t3-livechat-message-container {
	padding-left: 71px;
	padding-right: 57px;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	-webkit-user-select: text;
}

	.t3-livechat-message-container.in {
		padding-left: 4rem;
		padding-right: 1rem;
	}

		.t3-livechat-message-container.in .t3-livechat-message,
		.t3-live-chat-message-tail.in {
			background-color: white;
		}

	.t3-livechat-message-container.out {
		align-items: flex-end;
		padding-left: 1rem;
		padding-right: 1rem;
	}

.t3-livechat-message-wrapper {
	min-width: 30%;
	max-width: 65%;
	position: relative;
	flex: none;
	font-size: 0.8rem;
	line-height: 19px;
	color: #fff;
	border-radius: 7.5px;
}

.t3-live-chat-message-tail {
	left: -5px;
	position: absolute;
	top: 15px;
	z-index: 1000;
	display: block;
	width: 15px;
	height: 10px;
	background: #d3fdd5;
	transform: rotate(45deg);
}

	.t3-live-chat-message-tail.out {
		right: -5px !important;
		left: unset;
	}

.t3-live-chat-message-profile-wrapper {
	width: 36px;
	height: 36px;
	border-radius: 50%;
	left: -50px;
	position: absolute;
}

.t3-live-chat-message-profile {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	justify-content: center;
	align-items: center;
	display: flex;
	background: #06cf9c;
	color: #ffffff;
	overflow: hidden;
}

.t3-live-chat-message-alphabet {
	font-size: 1.5rem;
	line-height: 1rem;
	display: flex;
	justify-content: center;
}

	.t3-live-chat-message-alphabet > svg {
		position: relative;
		top: 4px;
		opacity: 0.5;
	}

	.t3-live-chat-message-alphabet > img {
		position: relative;
		width: 120%;
		top: 12px;
	}

.t3-livechat-message {
	background: #d3fdd5;
	color: #000;
	box-shadow: 0 1px 1.5px rgba(11, 20, 26, 0.13);
	position: relative;
	z-index: 200;
	border-radius: 7.5px;
}

@media (max-width: 1300px) {
	.t3-livechat-message-wrapper {
		max-width: 75%;
	}
}

@media (max-width: 900px) {
	.t3-livechat-message-wrapper {
		max-width: 95%;
	}

	.t3-livechat-message-container {
		padding-left: 53px;
		padding-right: 19px;
	}

	.footer-container {
		display: none;
	}

	.content.content-full-width {
		padding-bottom: 20px;
	}
}

.t3-livechat-content {
	padding-bottom: 8px;
	padding-top: 6px;
	padding-left: 9px;
	box-sizing: border-box;
	padding-right: 8px;
	-webkit-user-select: text;
}

.t3-livechat-username {
	display: inline-flex;
	max-width: 100%;
	font-size: 12.8px;
	line-height: 22px;
	font-weight: 500;
}

	.t3-livechat-username > span {
		min-height: 0px;
		flex: 0 1 auto;
		padding-left: 2px;
		margin-left: -2px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		visibility: visible;
		color: #03b487;
	}

	.t3-livechat-username.out > span {
		color: #f4a812;
	}

.t3-livechat-text-content > div {
	position: relative;
	word-wrap: break-word;
	white-space: pre-wrap;
}

.t3-livechat-text-content img {
	max-width: 200px;
	max-height: 200px;
	cursor: pointer;
}

.t3-livechat-text-content > div > span {
	min-height: 0px;
	visibility: visible;
	-webkit-user-select: text;
}

.t3-livechat-text-time {
	display: inline-flex;
	font-size: 0.6875rem;
	padding-right: 4px;
	line-height: 15px;
	padding-left: 4px;
	vertical-align: middle;
	padding-bottom: 0;
	padding-top: 0;
	float: right;
}

	.t3-livechat-text-time > span {
		flex-shrink: 0;
		flex-grow: 0;
	}

.t3-livechat-message-end {
	width: fit-content;
	bottom: 4px;
	align-self: flex-start;
	left: 6px;
	z-index: 200;
	display: flex;
	position: relative;
}

.t3-livechat-input-container {
	background: #f0f2f5;
	position: relative;
	z-index: 2;
	box-sizing: border-box;
	flex: none;
	order: 3;
	width: 100%;
	min-height: 55px;
	padding: 0;
	bottom: 0;
	position: absolute;
	z-index: 300;
}

.t3-livechat-input-wrapper {
	position: relative;
	z-index: 2;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	max-width: 100%;
	min-height: 55px;
	padding: 2px 10px;
}

.t3-livechat-input {
	position: relative;
	flex: 1;
	width: 100%;
	min-width: 0;
	min-height: 45px;
}

.t3-livechat-input-full {
	display: flex;
	align-items: flex-end;
}

.t3-livechat-input-left {
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	padding: 0;
	color: yellow;
	will-change: width;
	transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}

.t3-livechat-input-attachment-container {
	display: flex;
	align-items: center;
}

.t3-livechat-input-attachment-wrapper {
	margin: 0;
	position: relative;
	flex: none;
	height: 100%;
	border-radius: 50%;
	margin-bottom: 9px;
}

.t3-livechat-input-attachment-button {
	display: flex;
	align-items: center;
	cursor: pointer;
}

	.t3-livechat-input-attachment-button.disabled {
		opacity: 0.3;
	}

/*.t3-livechat-input-attachment-button-icon {
    width: 24px;
    height: 24px;
    background: #abc;
    position: relative;
}

.t3-livechat-input-attachment-button-icon:before {
    content:'';
    display: block;
    position: absolute;
    width: 3px;
    height: 20px;
    background: red;
    top: 2px; 
    left:10px;
}

.t3-livechat-input-attachment-button-icon:after {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 3px;
    background: red;
    top: 10px;
    left: 2px;
}*/

.t3-livechat-input-right {
	display: flex;
	flex: 1;
	align-items: flex-end;
	min-width: 0;
}

.t3-livechat-input-textarea-container {
	box-sizing: border-box;
	flex: 1 1 auto;
	width: inherit;
	min-width: 0;
	/* min-height: 46px; */
	padding: 5px 12px;
	margin: 5px 8px;
	font-size: 15px;
	font-weight: normal;
	line-height: 0;
	/* background-color: #434343; */
	background-color: #fff;
	border-radius: 8px;
	outline: none;
	will-change: width;
	display: flex;
	/* height: 36px; */
}

.t3-livechat-input-textarea-wrapper {
	width: 100%;
	position: relative;
}

.t3-livechat-input-textarea {
	padding-top: 20px;
	min-height: 36px;
	width: 100%;
	user-select: text;
	white-space: pre-wrap;
	word-break: break-word;
	overflow-x: hidden;
	overflow-y: auto;
	font-size: 0.875rem;
	/* line-height: 30px; */
	line-height: 1.5rem;
	background: transparent;
	border: none;
	resize: none;
	overflow: hidden;
	/* color: #fff; */
	color: #1f2225;
	height: 100%;
}

	.t3-livechat-input-textarea::placeholder {
		color: #747474;
	}

	.t3-livechat-input-textarea:focus-visible {
		outline: none;
	}

.t3-livechat-input-send,
.t3-livechat-input-voice {
	width: 40px;
	min-width: 40px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 40px;
	padding: 0;
	cursor: pointer;
}

	.t3-livechat-input-send.disabled {
		opacity: 0.3;
	}

.t3-livechat-input-voice {
	/* margin-right: 8px; */
}

	.t3-livechat-input-voice.recording > div {
		display: flex;
		width: 100%;
		align-items: center;
		gap: 8px;
	}

	.t3-livechat-input-voice .disabled {
		opacity: 0.3;
	}

.livechat-time {
	font-size: 0.65rem;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	line-height: normal;
}

.livechat-name {
	font-weight: 600;
	font-size: 0.875rem;
}

.livechat-content {
	max-height: 92px;
	overflow: hidden;
	text-overflow: ellipsis;
}

	.livechat-content div[data-type="text"] {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

.t3-livechat-text-content div[data-type="text"] {
}

.t3-livechat-text-content div[data-type="image"] {
	/* border-left: 4px solid green; */
	border-radius: 5px;
	background: rgba(0, 0, 0, .05);
	margin: -4px;
}

	.t3-livechat-text-content div[data-type="image"] a {
		width: 100%;
		display: block;
		text-align: center;
	}

.t3-livechat-text-content div[data-type="audio"] {
	line-height: 0;
}

.livechat-content img {
	max-width: 80px;
	max-height: 80px;
}

.livechat-content audio, .t3-livechat-text-content audio {
	width: 100%;
	max-height: 36px;
}

.t3-livechat-text-content audio {
	width: 300px;
}

.t3-livechat-input-send, .t3-livechat-input-voice {
	width: 40px;
	min-width: 40px;
	box-sizing: border-box;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	min-height: 40px;
	padding: 0;
	margin-bottom: 9px;
}

.t3-livechat-input-voice,
.t3-livechat-input-send {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s;
}

.t3-livechat-input-right.send-voice .t3-livechat-input-voice,
.t3-livechat-input-right.send-message .t3-livechat-input-send {
	opacity: 1;
	visibility: visible;
	position: absolute;
	right: 0;
	bottom: 0;
	width: fit-content;
}

/* Dark Mode - Uncomment to enable */
/* 
#chatroom {
	background-color: #0b141a;
}

.t3-livechat-input-container {
	background: #292929;
}

.t3-livechat-input-textarea-container {
	background-color: #434343;
}

.t3-livechat-message {
	background:#3c3c3c;
	color: #ffffff;
}

.t3-livechat-username > span {
	color: #45ff8b;
}

.t3-livechat-username.out > span {
	color: #f6c722;
}

.t3-live-chat-message-tail {
	background: #3c3c3c;
} */
