/*fonts*/
@font-face {
    font-family: "segoe";
    src: url(../fonts/SEGOEUI.TTF);
}

/*end fonts*/
.modal{
    overflow: auto !important;
}

/* ///richwin admin/// */
#richwin-section {
    min-height: 100vh;
    min-width: 100vh;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 10em 0px;
    color: #fff;
}

.bg-container {
    min-height: 100vh;
    min-width: 100vh;
    border: 5px solid red;
}

.richwin-box {
    max-width: 100%;
    margin: auto;
}

#richwin-section h2 {
    font-size: 34px;
    font-weight: 900;
    margin: auto;
}

    #richwin-section h2 span {
        font-size: 24px;
        font-weight: 500;
    }

.input-bg-white {
    background: #fff;
    color: #000;
}

.input-bg-white::-webkit-input-placeholder {
    color: #858585;
}

.recaptcha-text {
    color: #000;
    border-radius: 8px;
    background: #000;
    height: 45px;
    display: inline-block;
    font-weight: 900;
    font-family: "Roboto", sans-serif;
    font-size: 23px;
    padding: 6px 30px;
    letter-spacing: 2px;
}

.richwin-hr {
    height: 2px;
    width: 100%;
    border: 0px;
    background-color: #fff;
    margin: 35px 0px;
}

.white-long-btn {
    background: #fff;
    border-radius: 8px;
    border: 0px;
    font-family: "Roboto", sans-serif;
    color: #000;
    font-weight: 900;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    font-size: 18px;
    width: 100%;
}

.form-white-input {
    padding: 0px 20px 0px 20px;
    font-size: 11px;
    border-radius: 8px;
    /*background: #02214a;*/
    border: 1px solid #fff;
    color: #000;
    width: 100%;
    height: 45px;
}

.version-number {
    font-size: 16px;
    margin: auto;
}

/* ///end richwin admin/// */


.datatable-searchbar {
    width: 15%;
    margin-bottom: 20px;
    margin-left: auto;
}

.ReactTable .rt-thead {
    background-color: #d5dcdc;
}

span.badge {
    font-size: 12px;
    height: fit-content;
    padding: 5px 10px;
}

.display-flex {
    display: flex;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.lang-selector {
    width: 135px;
    margin-top: 6px;
}

img.bank-icon {
    width: 100px;
}

.dashboard-table td {
    font-size: 14px;
}

table td, table th, .todolist-title, .ReactTable div {
    font-size: 14px;
}

.page-sidebar-fixed .sidebar,
.header .navbar-header {
    box-shadow: 1px 1px 15px rgba(0,0,0,0.2);
    background: #1a2942;
}

.ck-content {
    height: 130px;
}

.login.login-v1 .login-container .login-body .login-content .form-control {
    background: rgba(0, 0,0,0.18);
    font-weight: 600;
}

.login.login-with-news-feed .news-feed.login-bg {
    left: 500px;
    right: 0px;
}

.login.login-with-news-feed .right-content.left-content {
    min-height: 100%;
    background: #fff;
    width: 500px;
    margin-right: auto;
    margin-left: 0;
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media (max-width: 1199.98px) {
    .login.login-with-news-feed .news-feed.login-bg {
        right: 0px;
    }

    .widget-stats, .widget.widget-stats {
        width: 100%;
    }
}

@media (max-width: 767.98px) {
    .login.login-with-news-feed .right-content.left-content {
        width: auto;
    }
}

@media (min-width: 1300px) {
    .widget-stats, .widget.widget-stats {
        margin: 0 -30px 20px -30px;
    }
}

.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    justify-content: center;
}

.thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    min-width: 100px;
    min-height: 100px;
    max-width: 200px;
    max-height: 200px;
    padding: 4px;
    box-sizing: border-box;
}

.thumbInner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
}

.dropzone-img {
    display: block;
    width: 100%;
    height: 100%;
}

.nav-link {
    cursor: pointer
}

.show-calendar {
    z-index: 10000 !important
}

.row-marginBottom-10 {
    margin-bottom: 10px
}

img.pokerCard-icon {
    width: 40px;
    height: 50px;
}

.sweet-alert h2 {
    font-size: 18px !important
}

.sweet-alert .text-muted {
    font-size: 15px !important;
    color: dimgrey !important
}

.member-bet-detail {
    border: 1px solid #dee2e6 !important;
    margin-top: -1px !important;
}

.nav.nav-tabs .nav-item .member-bet-nav.active, .nav.nav-tabs .nav-item .member-bet-nav:focus, .nav.nav-tabs .nav-item .member-bet-nav:hover {
    border: 1px solid #dee2e6 !important;
    border-bottom: 1px solid white !important;
}

.clickable-username {
    color: blue;
}

.clickable-username:hover {
    text-decoration: underline;
    cursor: pointer;
    user-select: none;
}

.hierarchy-line {
    width: 100%;
    margin: 15px 0 0 15px;
    font-size: medium;
    user-select: none;
    overflow-wrap: break-word;
    padding-right: 30px;
}

    .hierarchy-line > label {
        padding-right: 0.5%;
        font-weight: 700
    }

.fa-sync-alt {
    cursor: pointer;
    user-select: none;
    font-size: small
}

.table-winloss-report th {
    line-height: 1;
    font-weight: bold !important;
    padding: 3px 5px !important
}

.table-winloss-report td {
    padding: 0 5px !important;
    font-weight: 600;
}

.table-winloss-report th, .table-winloss-report td {
    vertical-align: middle !important;
    text-align: right;
    border-color: #a8a8a8 !important
}

.table-winloss-report thead tr th:first-child {
    width: 120px !important;
}

.text-clickable {
    color: #0078FF;
    text-decoration: underline
}

    .text-clickable:hover {
        cursor: pointer;
    }

.table-winloss-report thead tr:first-child th:nth-child(-n+7) {
    background-color: rgb(0 148 255 / 9%)
}

.table-winloss-report thead th:nth-child(8),
.table-winloss-report thead tr:nth-child(2) th:nth-child(n+1):nth-child(-n+6),
.table-winloss-report thead tr:nth-child(2) td:nth-child(n+7):nth-child(-n+12) {
    background-color: rgb(0 148 255 / 19%)
}

.table-winloss-report thead th:nth-child(9),
.table-winloss-report thead tr:nth-child(2) th:nth-child(n+7):nth-child(-n+13) {
    background-color: rgb(0 148 255 / 29%)
}

.table-selfwin-summary table th, .table-selfwin-summary table td {
    padding: 0 5px !important;
    border-color: #a7aeb5;
    color: #000;
}

.table-selfwin-summary table th, .table-selfwin-summary table tfoot td {
    font-weight: bold !important
}

.table-selfwin-summary table td {
    font-weight: 600
}

.table-selfwin-summary tr:nth-child(even) {
    background-color: #f0f0f0
}

.table-selfwin-summary tbody td:nth-child(5),
.table-selfwin-summary tbody td:nth-child(6),
.table-selfwin-summary tbody td:nth-child(7),
.table-selfwin-summary tbody td:nth-child(9),
.table-selfwin-summary tbody td:nth-child(10),
.table-selfwin-summary tfoot td:nth-child(5),
.table-selfwin-summary tfoot td:nth-child(6),
.table-selfwin-summary tfoot td:nth-child(7),
.table-selfwin-summary tfoot td:nth-child(9),
.table-selfwin-summary tfoot td:nth-child(10) {
    text-align: right;
}


.table-winloss-report tbody tr:nth-child(even) {
    background-color: rgb(0 148 255 / 19%)
}

.table-winloss-report th {
    font-weight: bold
}

.maintenance-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: white;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1rem;
    text-align: center;
    white-space: pre-line
}

    .maintenance-wrapper .maintenance-img {
        width: 80%;
        margin-bottom: 1.5rem;
    }

@media (min-width: 768px) {
    .maintenance-wrapper .maintenance-img {
        width: 400px
    }
}

.toast-brand {
    color: #000 !important
}

.toast-brand .toast-title {
    font-weight: bold;
}

.clickable-span{
    cursor :pointer;
}

.member-group .css-2b097c-container {
    width:70%;
}

button.job-button:focus {
    outline: none !important;
}

.pending-deposit-tag {
    background-color: red;
    margin-left: 10px;
    background-color: red;
    padding: 0px 5px;
    border-radius: 5px;
    color: white;
}

.icon-green-tick {
    background-color: #0d8735;
    color: white;
    height: 22px;
    width: 40px;
    padding: 5px;
    border-radius: 10px;
}

.menu-number-label {
    margin-right: 5px;
}

.sidebar .nav {
    padding-left: 15px !important;
    padding-right: 10px !important;
    color: #dee2e6 !important;
}

.phone-green-tick {
    position: absolute;
    right: 20px;
    top: 34px;
    background-color: #0d8735;
    color: white;
    height: 18px;
    width: 18px;
    padding: 3px;
    border-radius: 5px;
}

.rdtPicker td.rdtDisabled, .rdtPicker td.rdtDisabled:hover {
    background: none !important;
    color: #BBB !important;
    cursor: not-allowed !important
}

.process-table {
    font-size: 15px;
}

#processed {
    text-align: center;
}

.downline-title {
    color: grey;
    margin: 15px;
    font-size: 18px;
}

.bg-custom-yellow {
    background-color: #fe9f00 !important;
}

.bg-custom-purple {
    background-color: #963696 !important;
}

.total-share-payout thead tr th:nth-child(2) {
    text-align: right;
}

.stats-icon {
    line-height: 0 !important;
}

.transaction-page-tab {
    background-color: lightgrey;
    border-radius: 5px;
    margin: 0 5px;
    color: #616161 !important;
}

.transaction-page-tab.active {
    background-color: #0078ff !important;
    color: white !important;
    border-radius: 5px !important;
}

.transaction-journey-page-tab {
    background-color: #616161;
    border-radius: 5px;
    margin: 0 5px;
    color: white !important;
    font-weight:600;
}

    .transaction-journey-page-tab.active {
        background-color: #0078ff !important;
        color: white !important;
        border-radius: 0 !important;
    }

.live-monitor-btn {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
    padding: 0 10px;
    outline: none;
}
    .live-monitor-btn .text {
        margin-right: 10px;
        font-size: 13px;
        font-weight: bolder;
    }
    .live-monitor-btn:focus {
        outline: none;
    }

.sports-live-monitor-btn {
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
    padding: 0 10px;
    outline: none;
}

    .sports-live-monitor-btn .text {
        margin-right: 10px;
        font-size: 13px;
        font-weight: bolder;
    }

    .sports-live-monitor-btn:focus {
        outline: none;
    }

.dnd-btn {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 17px;
    width: 17px;
    margin: 17px 0 0 15px;
    cursor: pointer;
}

#live-monitor {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 9999;
    overflow-y: scroll;
    padding: 25px 50px;
}
    #live-monitor .filter-section {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

#sports-live-monitor {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 9999;
    overflow-y: scroll;
    padding: 25px 50px;
}

    #sports-live-monitor .filter-section {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

#sports-live-monitor {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 9999;
    overflow-y: scroll;
    padding: 25px 50px;
}

    #sports-live-monitor .filter-section {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

.data-amount-filter[type=number] {
    -moz-appearance: textfield;
}

.data-amount-filter::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.livedata-row {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
    .livedata-row:hover {
        white-space: unset;
        overflow: visible;
        text-overflow: unset;
    }

.clear-cache.nav-item {
    position: absolute;
    right: 25px;
}

.clear-cache.nav-item .nav-link {
    background-color: red;
    border-radius: 6px;
    color: white !important;
}

.background-color-normal {
}

.background-color-red {
    background-color: #f8cbad;
}

.background-color-yellow {
    background-color: #ffe699;
}

.background-color-green {
    background-color: #c6e0b4;
}

.background-color-blue {
    background-color: #b4c6e7;
}

.background-color-purple {
    background-color: #80008052;
}

.background-color-grey {
    background-color: #aeaaaa;
}

.live-data-cell {
    font-weight: 700;
    padding: 0 8px;
}

.sports-live-monitor .live-data-cell {
    font-weight: 700;
    padding: 0 8px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.table-responsive.live-monitor .table td {
    padding: 0;
    vertical-align: middle;
    border: 1px solid #a0a0a0 !important;
}

.table-responsive.live-monitor {
    overflow-x: unset !important;
}

    .table-responsive.sports-live-monitor .table td {
        padding: 0;
        height: 1px;
        vertical-align: middle;
    }

.table-responsive.sports-live-monitor {
    overflow-x: unset !important;
}

.vertical-hr {
    width: 0.5px;
    height: 15px;
    margin: 0 5px;
}

.live-data-category {
    width: 75px;
    text-align: center;
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.live-data-category.active{
    background-color: lightblue;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
}

    .header-wrapper .right-side-wrapper {
        display: flex;
    }

.tooltip-text {
    visibility: hidden;
    position: absolute;
    width: fit-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    z-index: 1;
}

    .tooltip-text.left {
        bottom: 60px;
        right: 60px;
    }

    .tooltip-text.right {
        bottom: 60px;
        right: 30px;
    }

.total-deposit-approved:hover .tooltip-text {
    visibility: visible !important;
}

.total-deposit-player:hover .tooltip-text {
    visibility: visible;
}

.total-withdrawal-player:hover .tooltip-text {
    visibility: visible;
}

.total-withdrawal-approved:hover .tooltip-text {
    visibility: visible;
}

.widget-stats .stats-icon, .widget.widget-stats .stats-icon {
    top: 60px !important;
}

.widget-stats .stats-icon, .widget.widget-stats.bg-blue .stats-icon {
    top: 55px !important;
}

.live-data-risk-level {
    width: 45px;
    text-align: center;
    border-radius: 7px;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
}

    .live-data-risk-level.active {
        background-color: lightblue;
    }

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 15px 10px;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    justify-content: space-between;
}

.accordion-body {
    padding: 0px 0px 20px 10px;
}

.sidebar .nav .sub-menu > li > div {
    color: #dee2e6;
    font-weight: 600;
    cursor: pointer;
}

.sidebar .sub-menu > li > div {
    padding: 3px 12px 3px 15px;
    display: block;
    color: #dee2e6;
    text-decoration: none;
    position: relative;
}

.sports-live-monitor .match {
    text-align: center;
}

    .sports-live-monitor .match .team-1 {
        color: blue;
    }

    .sports-live-monitor .match .team-2 {
        color: red;
    }

.sports-live-monitor .league {
    text-align: center;
}

.sports-live-monitor th:nth-child(1) {
    text-align: center;
}

.sports-live-monitor th:nth-child(2) {
    text-align: center;
}

.sports-live-monitor th:nth-child(3) {
    text-align: center;
}

.sports-live-monitor th:nth-child(4) {
    text-align: center;
}

.sports-live-monitor th:nth-child(5) {
    text-align: right;
}

.sports-live-monitor th:nth-child(6) {
    text-align: right;
}

.sports-live-monitor th:nth-child(7) {
    text-align: center;
}

.table-responsive.sports-live-monitor .table td {
    padding: 0;
    height: 1px;
    vertical-align: middle;
}

.table-responsive.sports-live-monitor .table-bordered td {
    border: 1px solid #a0a0a0 !important;
}

.sports-live-monitor .table thead tr th {
    border-bottom: 1px solid #a0a0a0;
}

.desktop-top-bettor {
    width: 35px;
    height: 20px;
    margin: 0 10px;
    text-align: center;
}

    .desktop-top-bettor::-webkit-outer-spin-button,
    .desktop-top-bettor::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

/* Firefox */
    .desktop-top-bettor[type=number] {
        -moz-appearance: textfield;
    }

.bet-limit-username {
    font-weight: bold;
    font-size: 15px;
    border: none;
    cursor: auto;
}

.bet-limit-username:focus-visible {
    outline: none;
}

.bet-limit-username-wrapper {
    display: flex;
}

.risk-lvl-wrapper {
    display: flex;
    align-items: center;
}
    .risk-lvl-wrapper .tooltip-text {
        right: 0;
        top: -28px;
    }

    .risk-lvl-wrapper:hover .tooltip-text {
        visibility: visible !important;
    }

.risk-lvl-checkbox {
    height: 30px;
    width: 30px;
    margin-bottom: 5px;
    margin-left: 10px;
}

.bet-detail-amount-select {
    appearance: none;
    text-align: center;
    width: 25px;
    cursor: pointer;
}

.restricted-ip-example {
    font-size: 8px;
}

.process-sales-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-space-between {
    justify-content: space-between;
}

.border-lightgrey-1 {
    border: 1px solid lightgrey;
}

.table-cell-padding-1 {
    padding: 5px 10px;
}

.font-bold {
    font-weight: bold;
}

.text-blue-custom {
    color: #00008d;
}

.text-red-custom {
    color: #db0000;
}

.reset-sh-btn {
    background: transparent;
    color: transparent;
    position: absolute;
    right: -45px;
    top: 10px;
}

.background-lightgrey-1 {
    background: #f6f6f6;
}

.wallet-recon-wrapper .row.rt-export-div {
    display: block !important;
}

.wallet-recon-wrapper .row,
.wallet-recon-wrapper .pagination {
    display: none !important;
}

.background-color-light-blue {
    background-color: #e4f8ff;
}

.background-color-dark-blue {
    background-color: #4ed1ff;
}

.widget-stats .stats-info span, .widget.widget-stats .stats-info span {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0;
    color: #FFFFFF;
}

.widget-stats .stats-icon.dailytask, .widget.widget-stats.bg-blue .stats-icon.dailytask {
    top: unset !important;
    bottom: 20px;
}

.svg-icon {
    cursor: pointer;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    overflow: visible;
} 

.svg-icon path {
    stroke: rgba(255,255,255,0.9);
    fill: none;
    stroke-width: 1;
}

.cont {
    position: relative;
    height: 100%;
    background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/slider-2.jpg');
    background-size: cover;
    overflow: auto;
}
.demo {
    width: 30rem;
    height: 40rem;
    overflow: hidden;
    margin: auto;
}
    
.login {
    position: relative;
    height: 100%;
    background: linear-gradient(180deg,rgba(76,75,255,.8) 0,rgba(0,0,0,.6));
    transition: opacity 0.1s, transform 0.3s cubic-bezier(0.17, -0.65, 0.665, 1.25);
    transform: scale(1);
} 
.login.inactive {
    opacity: 0;
    transform: scale(1.1);
}
    
.login__check {
    /* position: absolute;
    top: 66px;
    left: 50%;
    transform: translateX(-50%); */
    position: absolute;
    top: 12rem;
    left: 13.5rem;
    width: 14rem;
    height: 2.8rem;
    background: #fff;
    transform-origin: 0 100%;
    transform: rotate(-45deg);
}

.login__check:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 2.8rem;
    height: 5.2rem;
    background: #fff;
    box-shadow: inset -0.2rem -2rem 2rem rgba(0,0,0,0.2);
}
    
.login__form {
    position: absolute;
    top: 35%;
    left: 0;
    width: 100%;
    height: 50%;
    padding: 1.5rem 2.5rem;
    text-align: center;
}
    
.login__row {
    height: 5rem;
    padding-top: 1rem;
}

.login__row input, .login__row button {
    outline: none;
    border: none;
}
    
.login__icon {
    margin-bottom: -0.4rem;
    margin-right: 0.5rem;
}
.login__icon.name path {
    stroke-dasharray: 73.50196075439453;
    stroke-dashoffset: 73.50196075439453;
    animation: animatePath 2s 0.5s forwards;
}
.login__icon.pass path {
    stroke-dasharray: 92.10662841796875;
    stroke-dashoffset: 92.10662841796875;
    animation: animatePath 2s 0.5s forwards;
}
    
.login__input {
    display: inline-block;
    width: 22rem;
    height: 100%;
    padding-left: 1.5rem;
    font-size: 12px;
    background: #FFFFFF;
    /* color: #FDFCFD !important; */
}

.login__input.captcha {
    width: 15rem;
}

/* .login__input:-webkit-autofill,
.login__input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s;
} */
    
.login__submit {
    position: relative;
    width: 100%;
    height: 45px;
    margin: 2rem 0;
    color: rgba(255,255,255,0.8);
    background: #FF3366;
    font-size: 18px;
    border-radius: 3rem;
    cursor: pointer;
    overflow: hidden;
    transition: width 0.3s 0.15s, font-size 0.1s 0.15s;
    outline: none;
    border: none;
}
.login__submit:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -1.5rem;
    margin-top: -1.5rem;
    width: 3rem;
    height: 3rem;
    border: 2px dotted #fff;
    border-radius: 50%;
    border-left: none;
    border-bottom: none;
    transition: opacity 0.1s 0.4s;
    opacity: 0;
}
    
.login__submit.processing {
    width: 4rem;
    font-size: 0;
} 
.login__submit.processing:after {
    opacity: 1;
    animation: rotate 0.5s 0.4s infinite linear;
}    
    
.login.success {
    transition: transform 0.3s 0.1s ease-out, opacity 0.1s 0.3s, background-color 0.1s 0.3s;
    transform: scale(30);
    opacity: 0.9;
    /* background-color: rgba(255,255,255,0.95); */
}
.login.success:after {
    transition: opacity 0.1s 0s;
    opacity: 0;
    animation: none;
}   
    
.login__signup {
    font-size: 1.2rem;
    color: #ABA8AE;
} 
.login__signup a {
    color: #fff;
    cursor: pointer;
}
    
    
.ripple {
    position: absolute;
    width: 15rem;
    height: 15rem;
    margin-left: -7.5rem;
    margin-top: -7.5rem;
    background: rgba(0,0,0,0.4);
    transform: scale(0);
    animation: animRipple 0.4s;
    border-radius: 50%;
}
@keyframes animRipple {
    to {
        transform: scale(3.5);
        opacity: 0;
    }
}
@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}
@keyframes animatePath {
    to {
        stroke-dashoffset: 0;
    }
}

body.authentication-bg {
    background-image: url("../img/login-bg/auth-bg.jpg");
    background-repeat: no-repeat;
}

.header .navbar-brand b {
    color: #efefef !important;
}

.sidebar .nav>li.nav-profile>a,
.sidebar .nav>li.nav-profile .info small,
.sidebar .nav>li>a,
.sidebar .nav .sub-menu>li>a {
    color: #dee2e6 !important;
}

.sidebar .nav>li>a {
    border-radius: 0 !important;
}

.sidebar .nav>li.active>a {
    position: relative;
    z-index: 10;
    color: #3bc0c3 !important;
    background: rgba(255, 255, 255, 0.07) !important;
}

.sidebar .sub-menu>li.active>a {
    color: #3bc0c3 !important;
}

.sidebar .nav>li.expand>a, .sidebar .nav>li.expanding>a {
    background: rgba(255, 255, 255, 0.07) !important;
}

.sidebar .nav>li>a:focus, .sidebar .nav>li>a:hover,
.sidebar .sub-menu>li>a:focus, .sidebar .sub-menu>li>a:hover {
    background: none !important;
    color: #3bc0c3 !important;
}

.sidebar .nav > * {
    color: #dee2e6 !important;
}    

.header.navbar-default .navbar-toggle .icon-bar {
    background: #FFFFFF !important;
}

.purple-note {
  color: #7f3e98;
}

.note-editor.note-frame.panel.panel-default, .note-editor.note-airframe{
    border: 1px solid #00000032 !important;
    background-color: #ffffff;
}
.panel {
    background: #fff;
    border-radius: 0.75rem;
    box-shadow: 0rem 0.125rem 0.025rem rgba(31, 33, 36, 0.1), 0rem 0.025rem 0.175rem rgba(31, 33, 36, 0.1);
}
.panel.panel-default>.panel-heading {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 15px;
    border-bottom: 1px solid #00000032;
    flex-wrap: wrap;
    row-gap: 8px;
}

.ck.ck-balloon-panel{
    z-index: 9999;
}

.editor-modal{
    max-width: 80% !important;
}

.ck.ck-editor__main>.ck-editor__editable{
    height: 400px;
}

.ck.ck-balloon-panel{
    z-index: 9999 !important;
}